<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card color="success" inline title="RMF" class="px-5 py-3">
          <v-form>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="record.fields['200'][0].a[0].val"
                    :rules="validateValue('200', 'a')"
                    disabled
                    :hide-details="hided"
                    :dense="dense"
                    :label="'200a ' + $t('Nume')"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="record.fields['100'][0].a[0].val"
                    :rules="validateValue('100', 'a')"
                    disabled
                    :hide-details="hided"
                    :dense="dense"
                    :label="'100a ' + $t('Număr document')"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="record.fields['700'][0].a[0].val"
                    :rules="validateValue('700', 'a')"
                    disabled
                    :hide-details="hided"
                    :dense="dense"
                    :label="'700a ' + $t('Utilizator')"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="record.fields['600'][0].a[0].val"
                    autofocus
                    :hide-details="hided"
                    :dense="dense"
                    item-text="name"
                    item-value="value"
                    :label="'600a ' + $t('Acces')"
                    :items="dictionary.doc_access"
                  />
                </v-col>
              </v-row>
              <span class="d-none">{{ render }}</span>
              <v-row v-for="(fld, ind) in record.fields['606']" :key="'606' + ind" dense>
                <v-hover v-slot="{ hover }">
                  <v-col cols="12">
                    <span class="d-none">{{ render }}</span>
                    <s-autocomplete
                      v-model="fld.a[0].val"
                      :rules="validateValue('606', 'a')"
                      :label="'606a ' + $t('Etichete')"
                      :dense="dense"
                      :hide-details="hided"
                      :clearable="false"
                      view-type="marc"
                      :item-text="'fields.606.a.val'"
                      :item-value="'fields.606.a.val'"
                      collection="ebib_marc_doc"
                      @focus="$set(dictionary, '606avshow' + ind, true)"
                      @blur="$set(dictionary, '606avshow' + ind, false)"
                    >
                      <template #append-outer>
                        <div
                          v-show="dictionary['606avshow' + ind] || hover"
                          :class="{ 'd-inline-flex': dictionary['606avshow' + ind] || hover }"
                        >
                          <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.606', ind + 1)">
                            mdi-plus
                          </v-icon>
                          <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.606', ind)">
                            mdi-minus
                          </v-icon>
                        </div>
                      </template>
                    </s-autocomplete>
                  </v-col>
                </v-hover>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-textarea
                    v-model="record.fields['300'][0].a[0].val"
                    :rules="validateValue('300', 'a')"
                    :hide-details="hided"
                    :dense="dense"
                    rows="3"
                    :label="'300a ' + $t('Note')"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="record.fields['856'][0].c[0].val"
                    :rules="validateValue('856', 'c')"
                    disabled
                    :hide-details="hided"
                    :dense="dense"
                    :label="'856c ' + $t('Data creării')"
                  />
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="record.fields['856'][0].s[0].val"
                    :rules="validateValue('856', 's')"
                    disabled
                    :hide-details="hided"
                    :dense="dense"
                    :label="'856s ' + $t('Mărime')"
                  />
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="record.fields['900'][0].a[0].val"
                    :rules="validateValue('900', 'a')"
                    disabled
                    :hide-details="hided"
                    :dense="dense"
                    :label="'900a ' + $t('Descărcări')"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="record.fields['856'][0].d[0].val"
                    :rules="validateValue('856', 'd')"
                    disabled
                    :hide-details="hided"
                    :dense="dense"
                    :label="'856d ' + $t('Cale')"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="record.fields['010'][0].a[0].val"
                    :rules="validateValue('010', 'a')"
                    disabled
                    :hide-details="hided"
                    :dense="dense"
                    :label="'010a ' + $t('Hash')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <search-link ref="searchLink" select-event @select="setLinkValue" />
    <create-edit ref="createEdit" />
  </v-container>
</template>

<script>
import SearchLink from "../../../components/containers/SearchLink"
import CreateEdit from "../../../views/settings/AddDictionaryEdit"
import SDateTime from "../../../components/inputs/DateTime"
import SAutocomplete from "../../../components/inputs/Autocomplete"

import { EventBus } from "../../../EventBus"
import axios from "../../../plugins/axios"
//import axio from "axios"
export default {
  name: "DashboardDashboard",
  components: {
    SearchLink,
    CreateEdit,
    SDateTime,
    SAutocomplete
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"]
          ]
        }
      },
      dense: true,
      hided: false,
      fileName: "",
      file: {},
      urlImg: null,
      searchId: "",
      dictionary: {},
      linkArray: null,
      linkField: null,
      linkSubfield: null,
      linkIndex: null,
      selectId: null,
      render: 1,
      record: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          100: [{ ord: "", a: [{ ord: "", val: "" }] }],
          200: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          600: [{ ord: "", a: [{ ord: "", val: "" }] }],
          606: [{ ord: "", a: [{ ord: "", val: "" }] }],
          700: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          856: [{ ord: "", c: [{ ord: "", val: "" }], d: [{ ord: "", val: "" }], s: [{ ord: "", val: "" }] }],
          900: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "010": [{ ord: "", a: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      recordView: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          100: [{ ord: "", a: [{ ord: "", val: "" }] }],
          200: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          600: [{ ord: "", a: [{ ord: "", val: "" }] }],
          606: [{ ord: "", a: [{ ord: "", val: "" }] }],
          700: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          856: [{ ord: "", c: [{ ord: "", val: "" }], d: [{ ord: "", val: "" }], s: [{ ord: "", val: "" }] }],
          900: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "010": [{ ord: "", a: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      schema: {
        dictionary: ["all.doc_access", "all.TipCerere"]
      },
      rows: []
    }
  },

  computed: {
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  created() {
    if (this.schema.dictionary) {
      this.schema.dictionary.map(itm => this.getDictionaryValues(itm))
    }
    const country = []
    this.rows.map(itm => {
      country.push({ name: itm.value, value: itm.value })
    })
    EventBus.$on("dictionary-reload", dict => {
      this.$log("dictionary-reload ", dict)
      if (this.selectId == dict.id) {
        this.getDictionaryValues("all." + dict.dictionary)
        if (dict.select) {
          this.setLinkValue(dict.select)
        }
      }
    })
    // EventBus.$on("table-form-select", value => {
    //   this.$log("selected ", value)
    // })
    //this.$log(JSON.stringify(country))
    // axios.post("anaf_check_tva", [{ cui: 18158683, data: "2020-04-30" }]).then(response => {
    //   this.$log("response ", response)
    // })
  },
  methods: {
    validateValue(fld, sub) {
      const rule = []
      //rule.push(v => !!v || this.$t("required"))
      return rule
    },
    getInv() {
      axios.get('ebib_loan_active?aggregate={"$where":{},"$sort":{}}&max_results=10').then(response => {
        this.$log("resinvtm ", response)
      })
    },
    createURL(img, fil) {
      this.$set(this.dictionary, img, URL.createObjectURL(fil.target.files[0]))
    },
    fileChanged(e) {
      this.file = e.target.files[0]
      this.urlImg = URL.createObjectURL(this.file)
      this.fileName = this.file.name
    },
    clearFile() {
      this.fileName = ""
      this.file = null
    },
    createAndEdit(e, arr, field, ind) {
      //searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.selectId = Date.now()
      this.$log("createAndEdit ", e)
      this.$refs.createEdit.showData(e, this.selectId)
    },
    checkTVA(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
          }
        })
      }
    },
    loadCUIData(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
            this.record.name = response.data.found[0].denumire
            const adr = response.data.found[0].adresa.split(",")
            if (adr.length > 2) {
              this.record.address.address = response.data.found[0].adresa.replace(adr[0] + "," + adr[1] + ",", "")
            }
            this.record.address.locality = adr[1]
            this.record.address.country = "România"
            if (adr[0].substr(0, 3) == "JUD") {
              const jud = adr[0].substr(5).toLowerCase()
              this.record.address.county = adr[0].substr(5)
              this.dictionary.county.map(itm => {
                if (itm.value.toLowerCase() == jud) {
                  this.record.address.county = itm.value
                }
              })
            } else {
              this.record.address.county = "Bucureşti"
            }
          }
        })
      }
    },
    getdictionary(dic) {
      return this.$store.getters.dictionary(dic)
    },
    getDictionaryValues(dic) {
      //this.$log("getDictionaryValues(itm) ", dic)
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        //axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
        this.$set(this.dictionary, dictionary[1], this.getdictionary("all_" + dictionary[1]))
        // this.dictionary[dictionary[1]] = response.data._items[0].content
        //})
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
          // this.dictionary[dictionary[1]] = response.data._items[0].content
        })
      }
    },
    addSubItem(e, ind) {
      if (ind == null) {
        this.resolvePath(e, this.record).push(JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0])))
      } else {
        this.resolvePath(e, this.record).splice(
          ind,
          0,
          JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0]))
        )
      }
    },
    delSubItem(e, ind) {
      this.resolvePath(e, this.record).splice(ind, 1)
      if (this.resolvePath(e, this.record).length == 0) {
        this.addSubItem(e)
      }
    },
    searchRelationMarc(field, subfield, ind, prefix, ctg) {
      this.linkField = field
      this.linkSubfield = subfield
      this.linkIndex = ind
      //this.$log("srcrel ", this.schemaContent[fields.field + subfield])
      this.searchId = Date.now()
      let where = ""
      let vTable = ""
      if (ctg) {
        where = '"ctg":' + '"' + ctg + '"'
        const ctgview = this.$store.getters.appViewTableCollectionCategory("marc_" + prefix, ctg)
        if (ctgview.length > 0) {
          vTable = ctgview[0]._id
        }
      }
      this.$refs.searchLink.open("ebib_marc_" + prefix, null, this.searchId, {
        where: where,
        viewTable: vTable,
        ctg: ctg
      })
      // EventBus.$emit("search-link", {
      //   resource: "ebib_marc_" + prefix,
      //   field: null,
      //   id: this.searchId,
      //   config: { where: where, viewTable: vTable, ctg: ctg }
      // })
      //this.$refs.searchLink.open(res, fld, this.searchId)
    },
    searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.searchId = Date.now()
      this.$refs.searchLink.open(res, fld, this.searchId)
    },
    setLink(field, subfield, ind, prefix, ctg, e) {
      this.$log("eeee ", e)
    },
    setLinkValue(e) {
      if (this.linkIndex != null) {
        if (this.linkField != null) {
          //this.linkArray[this.linkIndex][this.linkField] = e
          this.$set(this.linkArray[this.linkIndex], this.linkField, e)
        } else {
          if (typeof e === "object") {
            e.iiid = this.idGlobal++
          }
          if (this.linkIndex == -1) {
            this.linkArray.push(e)
          } else {
            //this.linkArray[this.linkIndex] = e
            this.$set(this.linkArray, this.linkIndex, e)
          }
        }
      } else {
        //this.linkArray[this.linkField] = e
        this.$set(this.linkArray, this.linkField, e)
        this.render = Date.now()
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
